import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/banner-half-photo-text.scss'

class BannerHalfPhotoText extends React.Component {
  constructor(props) {
    super(props)
    this.bannerSizeClass
  } 

  render() {
    switch (this.props.bannerSize) {
      case 'wide':
        this.bannerSizeClass = 'container'
        break
      case 'medium':
        this.bannerSizeClass = 'container medium'
        break
      default: 
        this.bannerSizeClass = ''
    }

    return (
      <section className={`banner-half-photo-text ${this.props.bannerSize} ${this.props.bannerClass}`}>
        <div className={this.bannerSizeClass}>
          <div className={`row no-gutters align-items-center ${this.props.bannerBackground}`}>
            <div className={this.props.reverseDirection?"col-md-6 order-0 order-md-2 align-self-stretch":"col-md-6 align-self-stretch"}>
              <GatsbyImage image={this.props.bannerImage.image} alt={this.props.bannerImage.alt} />
            </div>
            <div className={this.props.reverseDirection?"col-md-6 order-0 order-md-1 d-flex justify-content-end":"col-md-6"}>
              <div className="banner-half__content">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

BannerHalfPhotoText.defaultProps = {
  bannerClass: "",
  bannerSize: "wide",
  bannerBackground: "",
  reverseDirection: false
}

export default BannerHalfPhotoText