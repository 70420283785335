import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import HeroVideo from '../components/sections/hero-video'
import BannerLeftPhotoThreeColumns from '../components/sections/banner-left-photo-three-columns'
import BannerRightPhotoThreeColumns from '../components/sections/banner-right-photo-three-columns'
import BannerLeftPhotoTwoColumns from '../components/sections/banner-left-photo-two-columns'
import BannerHalfPhotoText from '../components/sections/banner-half-photo-text'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-videophotography.scss'

import { videophotography as servicesSchema } from '../utils/schema/services'

const pageVideoPhotography = ({ location }) => {
  const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-video-photography"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-videophotography.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  video1: file(relativePath: {eq: "videophotography/video1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 345, layout: CONSTRAINED)
    }
  }
  video2: file(relativePath: {eq: "videophotography/video2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 345, layout: CONSTRAINED)
    }
  }
  video3: file(relativePath: {eq: "videophotography/video3.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 345, layout: CONSTRAINED)
    }
  }
  video4: file(relativePath: {eq: "videophotography/video4.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 345, layout: CONSTRAINED)
    }
  }
  video5: file(relativePath: {eq: "videophotography/video5.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 595, layout: CONSTRAINED)
    }
  }
  photoProduct: file(relativePath: {eq: "videophotography/photo_product.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  photoStudio: file(relativePath: {eq: "videophotography/photo_studio.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 585, layout: CONSTRAINED)
    }
  }
  photoEvents: file(relativePath: {eq: "videophotography/photo_events.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  photoRealestate: file(
    relativePath: {eq: "videophotography/photo_realestate.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 585, layout: CONSTRAINED)
    }
  }
}
`)
	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
      </Helmet>
      <Layout>
        <article className="videophotography">
          <HeroVideo
            heroImage={data.hero.childImageSharp.gatsbyImageData}
            heroVideoSrc='https://www.youtube.com/watch?v=94ueJSH1aoI'
            heroText={'ΒΙΝΤΕΟ &amp; ΦΩΤΟΓΡΑΦΙΑ'}
          />
          <BannerLeftPhotoThreeColumns
            bannerImage={{image:data.video1.childImageSharp.gatsbyImageData, alt: "Εταιρικά Video"}}
            bannerHeader='Εταιρικά Video'
            bannerPunchline='Δημιουργούμε εταιρικά video για εκθέσεις, τηλεοπτική προβολή, διαδικτυακή παρουσία σε ποιότητα Full HD!'>
            <p>Δημιουργούμε εταιρικά video που να ανταποκρίνεται πλήρως στην εικόνα και τις αξίες της εταιρείας σας. Διαθέτουμε εξειδικευμένο προσωπικό σε όλο το φάσμα της παραγωγής με πολύχρονη εμπειρία. Στο pre-production στάδιο, σχεδιάζουμε το ύφος και τη δομή του video. Με γνώμονα την αισθητική και τους στόχους σας, το δημιουργικό μας τμήμα καταρτίζει το story board (storytelling, animation ή φυσικά πρόσωπα, casting), και σας το παρουσιάζουμε προς έγκριση.</p>
            <p>Ακολουθεί το στάδιο της παραγωγής όπου πραγματοποιούνται τα γυρίσματα ή οι συνθέσεις των εικόνων (σε περίπτωση που δεν απαιτούνται γυρίσματα). Στο post production στάδιο, οι έμπειροι μοντέρ μας κάνουν τις τελευταίες πινελιές (χρωματικές διορθώσεις, προσθήκη εφέ και γραφικών, μιξάζ ήχου) και μεταγράφουν το εταιρικό video στα κατάλληλα μέσα για τη διανομή του. </p>
            <strong className="h5">Επιλέξτε τη Think Plus για την παραγωγή του εταιρικού σας video και αποκτήστε ένα δυναμικό εργαλείο προβολής!</strong>
          </BannerLeftPhotoThreeColumns>
          <BannerRightPhotoThreeColumns
            bannerImage={{image: data.video2.childImageSharp.gatsbyImageData, alt: "Διαφημιστικά Σποτ"}}
            bannerHeader='Διαφημιστικά Σποτ'
            bannerPunchline='Διαφημιστικά σποτ για τηλεόραση, internet και εσωτερικά δίκτυα προβολής.'>
            <p>Δημιουργούμε το δικό σας προϊοντικό video και αυξάνουμε θεαματικά την επιθυμία του καταναλωτή προς το προϊόν σας. Διαθέτουμε εξειδικευμένο προσωπικό σε όλο το φάσμα της παραγωγής με πολύχρονη εμπειρία. Στο pre-production στάδιο, καταρτίζουμε το storytelling, επιλέγουμε την τοποθεσία για τα γυρίσματα και το casting και σας τα παρουσιάζουμε προς έγκριση.</p>
            <p>Ακολουθεί το στάδιο της παραγωγής όπου πραγματοποιούνται τα γυρίσματα ή οι συνθέσεις των εικόνων (σε περίπτωση που δεν απαιτούνται γυρίσματα). Στο post production στάδιο, οι έμπειροι μοντέρ μας κάνουν τις τελευταίες πινελιές (χρωματικές διορθώσεις, προσθήκη εφέ και γραφικών, μιξάζ ήχου) και μεταγράφουν το video στα κατάλληλα μέσα για τη διανομή του.</p>
            <strong className="h5">Επιλέξτε τη Think Plus για τα πιο viral διαφημιστικά σποτ! Επικοινωνήστε μαζί μας για περισσότερες πληροφορίες.</strong>
          </BannerRightPhotoThreeColumns>
          <BannerLeftPhotoThreeColumns
            bannerImage={{image: data.video3.childImageSharp.gatsbyImageData, alt: "Viral Video"}}
            bannerHeader='Viral Video'
            bannerPunchline='Το τμήμα παραγωγής βίντεο της Think Plus αποτελείται από επαγγελματίες με πολύχρονη εμπειρία και εξειδίκευση σε όλο το φάσμα παραγωγής ενός βίντεο.'>
            <p>Εμείς στη Τhink Plus ‘ανακατεύουμε’ τη viral τάση με στρατηγικές μάρκετινγκ, και δημιουργούμε αποτελεσματικές viral video εκστρατείες με ισχυρή αλληλεπίδραση.</p>
            <p>Προσεγγίζουμε το target group και κάνουμε share του video σε ιστοσελίδες και social media που φέρνουν το μέγιστο δυνατό engagement! Επιλέξτε τη Think plus για την προώθηση του video σας και γίνετε το νέο viral star στο κοινό σας!</p>
            <strong className="h5">Επιλέξτε τη Think Plus για την προώθηση του video σας και γίνετε το νέο viral star στο κοινό σας!</strong>
          </BannerLeftPhotoThreeColumns>
          <BannerRightPhotoThreeColumns
            bannerImage={{image: data.video4.childImageSharp.gatsbyImageData, alt: "Video Strategy"}}
            bannerHeader='Video Strategy'
            bannerPunchline='Εμπιστευτείτε μας τώρα για το σχεδιασμό του δικού σας Video Strategy και απογειώστε την εταιρική σας παρουσία!'>
            <p>Εμείς στη Think Plus παρέχουμε μία ολοκληρωμένη στρατηγική Video Marketing που θα σας εξασφαλίζει την απόλυτη επιτυχία. Είμαστε δίπλα σας, από τη στιγμή που θα σας έρθει η ανάγκη για τη δημιουργία video μέχρι τα reports που θα συνοδεύουν τη λήξη της καμπάνιας μάρκετινγκ.</p>
            <p>Ακόμα και αν έχετε έτοιμο ένα πολλά υποσχόμενο video, αυτό δεν σημαίνει απαραίτητα ότι θα τύχει ευρείας αποδοχής από το κοινό σας.Το εξειδικευμένο μας προσωπικό αναλαμβάνει εξολοκλήρου την παραγωγή του video από το σχεδιασμό μέχρι και την διανομή, καθώς και την υλοποίηση της συνολικής online video εκστρατείας.</p>
            <strong className="h5">Παρακολουθούμε διαρκώς την πορεία της καμπάνιας και στοχεύουμε πάντα σε viral αποτελέσματα.</strong>
          </BannerRightPhotoThreeColumns>
          <BannerLeftPhotoTwoColumns
            bannerImage={{image: data.video5.childImageSharp.gatsbyImageData, alt: "Viral SEO"}}
            bannerHeader='Viral SEO'>
            <strong className="h5">Έχετε το τέλειο video… Κάνετε Youtube SEO;</strong>
            <p>Εμείς στη Think Plus εφαρμόζουμε τις κατάλληλες τεχνικές Search Engine Optimization και εκτινάζουμε το video σας την πρώτη σελίδα των μηχανών αναζήτησης!</p>
            <p>Εκμεταλλευτείτε τη δυναμική των youtube και vimeo για την αύξηση της online επιρροής σας. Οι SEO τεχνικές των έμπειρων developers μας θα φέρουν το video σας στη θέση που της αξίζει: Στην κορυφή!</p>
            <strong className="h5">Επικοινωνήστε μαζί μας για να σας προσφέρουμε αυτό που πραγματικά χρειάζεστε!</strong>
          </BannerLeftPhotoTwoColumns>
        </article>
        <article className="photographyvideo">
          <BannerHalfPhotoText
            bannerSize = 'full'
            bannerBackground='background__theme--dark'
            bannerImage={{image:data.photoProduct.childImageSharp.gatsbyImageData, alt: "Φωτογράφιση Προϊόντων"}}
            reverseDirection={true}
          >
            <h2 className="dec-element--line-horizontal">Φωτογράφιση Προϊόντων</h2>
            <p>Δείτε μέσα από ένα σύγχρονο πρίσμα τα προϊόντα e-shop, site και καταλόγου σε υψηλή ποιότητα. Παρέχουμε επαγγελματική φωτογράφιση προϊόντων με σύγχρονες τεχνικές, φωτογράφιση 360, ghost mannequin effect και άλλων με απόλυτη συνέπεια.</p>
            <p>Κερδίστε το κοινό και δείτε τους πελάτες σας να αυξάνονται καθημερινά. Μια εικόνα αξίζει χίλιες λέξεις. H επαγγελματική φωτογράφιση προσθέτει αξία και αξιοποιεί τις δυνατότητες επικοινωνίας με το σύγχρονο απαιτητικό κοινό. Σχεδιάζουμε για εσάς την κατάλληλη στρατηγική για την υπηρεσία φωτογράφισης και επιτυγχάνουμε το αποτέλεσμα που ονειρεύεστε! Η χρυσή αναλογία της επιτυχίας.</p>
            <strong className="h5">Think Plus για τη δική σας επαγγελματική προϊοντική φωτογράφιση. Ας δούμε μαζί τις πραγματικές σας ανάγκες.</strong>
          </BannerHalfPhotoText>
          <BannerHalfPhotoText
            bannerClass='text-align-right'
            bannerSize = 'medium'
            bannerBackground='background__theme'
            bannerImage={{image:data.photoStudio.childImageSharp.gatsbyImageData, alt: "Φωτογράφιση στο studio"}}
            reverseDirection={true}
          >
            <h2 className="dec-element--line-horizontal">Φωτογράφιση στο studio</h2>
            <p>Η επαγγελματική φωτογράφιση στο studio θα σας επιτρέψει να δείτε και να προσφέρετε περισσότερα στους πελάτες σας. Η καλή φωτογραφία χρειάζεται το κατάλληλο φως. Στο studio φωτογράφισης που διαθέτουμε έχουμε σχεδιάσει με ακρίβεια κάθε στοιχείο φωτισμού. Επαγγελματίες μοντέλα «φοράνε» τα προϊόντα σας και ο φακός εστιάζει στην επιτυχία. Κάθε κλικ είναι σημαντικό!</p>
            <strong className="h5">Think Plus για τη δική σας επαγγελματική στουντιακή φωτογράφιση. Επιτρέψτε μας να είμαστε οι επαγγελματίες που θέλετε!</strong>
          </BannerHalfPhotoText>
          <BannerHalfPhotoText
            bannerClass='text-align-right'
            bannerSize = 'full'
            bannerBackground='background__theme--dark'
            bannerImage={{image:data.photoEvents.childImageSharp.gatsbyImageData, alt: "Φωτογράφιση εκδηλώσεων"}}
            reverseDirection={false}
          >
            <h2 className="dec-element--line-horizontal">Φωτογράφιση εκδηλώσεων</h2>
            <p>Χιλιάδες events και εκδηλώσεις πραγματοποιούνται κάθε μέρα. Οι απαιτήσεις σ’ αυτό το σύγχρονο και δυναμικό περιβάλλον είναι πάντοτε υψηλές.  Η εμπειρία μας στην κάλυψη εκδηλώσεων, όπως corporate events, product activations, night shows, business days, open space, conventions και πολλές ακόμα, μας επιτρέπει να παρέχουμε σύγχρονες υπηρεσίες επαγγελματικής φωτογράφισης εκδηλώσεων που ταιριάζουν ακριβώς στις ανάγκες σας. Ο επαγγελματισμός και η συνέπεια αποτελούν για μας τα καλύτερα μέσα υλοποίησης κάθε project. Η δύναμη της εικόνας σήμερα.</p>
            <strong className="h5">Think Plus για τη φωτογράφιση των εκδηλώσεων και κρατήστε τις στιγμές που έχουν αξία!  Επιτρέψτε μας να συνεργαστούμε για το τέλειο αποτέλεσμα!</strong>
          </BannerHalfPhotoText>
          <BannerHalfPhotoText
            bannerSize = 'medium'
            bannerBackground='background__theme'
            bannerImage={{image:data.photoRealestate.childImageSharp.gatsbyImageData, alt: "Φωτογράφιση Real Estate"}}
            reverseDirection={false}
          >
            <h2 className="dec-element--line-horizontal">Φωτογράφιση Real Estate</h2>
            <p>Οι επαγγελματίες και η ομάδα μας διαθέτουν την απαραίτητη εμπειρία για ένα είδος σύγχρονης φωτογράφισης, όπως η φωτογράφιση χώρων real estate με διεθνή πρότυπα. Αναλαμβάνουμε το project σας σε οποιοδήποτε σημείο στην Ελλάδα. Επαγγελματική φωτογράφιση χώρων, ξενοδοχείων και άλλων χώρων με στόχο την ανάδειξη τους με τον καλύτερο δυνατό τρόπο αλλά και τη σωστή προώθηση στις πλατφόρμες του διαδικτύου. Όλα σε ένα πλάνο.</p>
            <strong className="h5">Think Plus για τη φωτογράφιση των χώρων που έχουν πραγματική σημασία. Επιτρέψτε μας να συνεργαστούμε για το τέλειο αποτέλεσμα!</strong>
          </BannerHalfPhotoText>
        </article>
        <ContactForm />
      </Layout>
    </>;
}

export default pageVideoPhotography
