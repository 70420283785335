import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/banner-right-photo-three-columns.scss'

class BannerRightPhotoThreeColumns extends React.Component {
  render() {
    return (
      <section className="banner-right-photo-three-columns background__theme--dark">
        <div className="container">
          <div className="row align-items-center text-align-right">
            <div className="col-lg-8 col-md-7 order-2 order-md-1 order-lg-0 banner__content">
              <h2 className="dec-element--line-horizontal">{this.props.bannerHeader}</h2>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 order-2 order-lg-0 offset-md-1 align-self-start banner__childrens dec-element--line-horizontal">{this.props.children}</div>
                  <div className="col-lg-3 order-1 order-lg-0 align-self-end">
                    <strong className="h5 banner__punchline">{this.props.bannerPunchline}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 order-1 order-md-2">
              <GatsbyImage
                image={this.props.bannerImage.image}
                alt={this.props.bannerImage.alt}
                className="banner__photo" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BannerRightPhotoThreeColumns