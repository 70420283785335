import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/banner-left-photo-two-columns.scss'

class BannerLeftPhotoTwoColumns extends React.Component {
  render() {
    return (
      <section className="banner-left-photo-two-columns background__theme">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <GatsbyImage image={this.props.bannerImage.image} alt={this.props.bannerImage.alt} />
            </div>
            <div className="col-lg-5">
              <h2 className="dec-element--line-horizontal">{this.props.bannerHeader}</h2>
              <div className="align-self-start banner__childrens dec-element--line-horizontal">{this.props.children}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BannerLeftPhotoTwoColumns