import React from 'react'
import SocialIcons from '../common/SocialIcons'
import ReactPlayer from 'react-player'

import '../../styles/components/hero-video.scss'

class HeroVideo extends React.Component {
  render() {
    return (
      <section className="hero-video dec-section--box-top">
        <link
          rel="stylesheet"
          href="https://video-react.github.io/assets/video-react.css"
        />
        <div className="container">
          <div className="row">
            <div className="col text-align-right">
              <SocialIcons type={"light"} position={"relative"} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="hero-video__player">
                <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer 
                    className="react-player"
                    url={this.props.heroVideoSrc} 
                    width='100%'
                    height='100%'
                    playing={true}
                    playsinline
                    controls
                    light={this.props.heroImage.src}
                    playIcon={<div className="hero-video__play"><span className="h5">PLAY</span></div>}
                    config={{
                      youtube: {
                        embedOptions: {
                          color: 'white'
                        },
                        preload: true
                      }
                    }}
                  />
                </div>
              </div>
              <h1 dangerouslySetInnerHTML={{ __html: this.props.heroText }} className="h2 dec-element--line-vertical" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroVideo