import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/banner-left-photo-three-columns.scss'

class BannerLeftPhotoThreeColumns extends React.Component {
  render() {
    return (
      <section className="banner-left-photo-three-columns background__theme">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-5">
              <GatsbyImage
                image={this.props.bannerImage.image}
                alt={this.props.bannerImage.alt}
                className="banner__photo" />
            </div>
            <div className="col-lg-8 col-md-7 banner__content">
              <h2 className="dec-element--line-horizontal">{this.props.bannerHeader}</h2>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 align-self-end">
                    <strong className="h5 banner__punchline">{this.props.bannerPunchline}</strong>
                    </div>
                  <div className="col-lg-9 align-self-start banner__childrens dec-element--line-horizontal">{this.props.children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BannerLeftPhotoThreeColumns